var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mx-auto max-w-4xl" },
    [
      _c("h1", { staticClass: "text-center my-8" }, [
        _vm._v("作品を積極的にライセンスしましょう"),
      ]),
      _vm._m(0),
      _c(
        "h2",
        {
          staticClass:
            "text-gray-700 text-center mt-12 mb-2 text-sm font-normal",
        },
        [_vm._v("プレミアムプランに入ると以下のことが可能になります。")]
      ),
      _c("ul", [
        _c(
          "li",
          {
            staticClass:
              "border p-4 shadow-md rounded-md flex items-start border-gray-400",
          },
          [
            _c("div", { staticClass: "h-8 w-8 flex-none" }, [
              _c(
                "svg",
                {
                  staticClass: "size-6",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M7.5 14.25v2.25m3-4.5v4.5m3-6.75v6.75m3-9v9M6 20.25h12A2.25 2.25 0 0 0 20.25 18V6A2.25 2.25 0 0 0 18 3.75H6A2.25 2.25 0 0 0 3.75 6v12A2.25 2.25 0 0 0 6 20.25Z",
                    },
                  }),
                ]
              ),
            ]),
            _vm._m(1),
          ]
        ),
        _c(
          "li",
          {
            staticClass:
              "border p-4 shadow-md rounded-md mt-4 flex items-start border-gray-400",
          },
          [
            _c("div", { staticClass: "h-8 w-8 flex-none" }, [
              _c(
                "svg",
                {
                  staticClass: "size-6",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M8.25 18.75a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h6m-9 0H3.375a1.125 1.125 0 0 1-1.125-1.125V14.25m17.25 4.5a1.5 1.5 0 0 1-3 0m3 0a1.5 1.5 0 0 0-3 0m3 0h1.125c.621 0 1.129-.504 1.09-1.124a17.902 17.902 0 0 0-3.213-9.193 2.056 2.056 0 0 0-1.58-.86H14.25M16.5 18.75h-2.25m0-11.177v-.958c0-.568-.422-1.048-.987-1.106a48.554 48.554 0 0 0-10.026 0 1.106 1.106 0 0 0-.987 1.106v7.635m12-6.677v6.677m0 4.5v-4.5m0 0h-12",
                    },
                  }),
                ]
              ),
            ]),
            _vm._m(2),
          ]
        ),
        _c(
          "li",
          {
            staticClass:
              "border p-4 shadow-md rounded-md mt-4 flex items-start border-gray-400",
          },
          [
            _c("div", { staticClass: "h-8 w-8 flex-none" }, [
              _c(
                "svg",
                {
                  staticClass: "size-6",
                  attrs: {
                    xmlns: "http://www.w3.org/2000/svg",
                    fill: "none",
                    viewBox: "0 0 24 24",
                    "stroke-width": "1.5",
                    stroke: "currentColor",
                  },
                },
                [
                  _c("path", {
                    attrs: {
                      "stroke-linecap": "round",
                      "stroke-linejoin": "round",
                      d: "M18 18.72a9.094 9.094 0 0 0 3.741-.479 3 3 0 0 0-4.682-2.72m.94 3.198.001.031c0 .225-.012.447-.037.666A11.944 11.944 0 0 1 12 21c-2.17 0-4.207-.576-5.963-1.584A6.062 6.062 0 0 1 6 18.719m12 0a5.971 5.971 0 0 0-.941-3.197m0 0A5.995 5.995 0 0 0 12 12.75a5.995 5.995 0 0 0-5.058 2.772m0 0a3 3 0 0 0-4.681 2.72 8.986 8.986 0 0 0 3.74.477m.94-3.197a5.971 5.971 0 0 0-.94 3.197M15 6.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm6 3a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Zm-13.5 0a2.25 2.25 0 1 1-4.5 0 2.25 2.25 0 0 1 4.5 0Z",
                    },
                  }),
                ]
              ),
            ]),
            _vm._m(3),
          ]
        ),
      ]),
      _vm._m(4),
      _c(
        "div",
        {
          staticClass:
            "border p-4 shadow-md rounded-md mt-10 bg-green-500 text-white flex items-start",
        },
        [
          _c("div", { staticClass: "h-8 w-8 flex-none" }, [
            _c(
              "svg",
              {
                staticClass: "size-6",
                attrs: {
                  xmlns: "http://www.w3.org/2000/svg",
                  fill: "none",
                  viewBox: "0 0 24 24",
                  "stroke-width": "1.5",
                  stroke: "currentColor",
                },
              },
              [
                _c("path", {
                  attrs: {
                    "stroke-linecap": "round",
                    "stroke-linejoin": "round",
                    d: "m3.75 13.5 10.5-11.25L12 10.5h8.25L9.75 21.75 12 13.5H3.75Z",
                  },
                }),
              ]
            ),
          ]),
          _vm._m(5),
        ]
      ),
      _c(
        "button",
        {
          staticClass:
            "w-full mx-auto h-full mt-8 py-3 rounded text-center font-bold bg-off-yellow hover:bg-off-yellow-lighter active:bg-off-yellow-darker disabled:bg-very-light-grey disabled:cursor-not-allowed",
          on: {
            click: function ($event) {
              return _vm.sendRequestPremium()
            },
          },
        },
        [_vm._v(" プレミアムプランを申し込む ")]
      ),
      _c("NoticeModal", {
        attrs: {
          modalName: "sentPremiumRequestModal",
          contents:
            "お申込みありがとうございます。数日以内に、担当者よりメールにてご連絡を差し上げますのでしばらくお待ちください。",
          buttons: ["OK"],
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mx-12" }, [
      _c("span", [
        _vm._v(
          "弊社の今までの海外への作品ライセンシングのノウハウを生かして、より多くの作品が海外で視聴されるよう、我々のノウハウの一部にアクセスできるサービスをご準備しました。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [_vm._v("分析ツールを活用")]),
      _c("p", { staticClass: "text-gray-500" }, [
        _vm._v(
          " どのバイヤーが、御社作品情報を何回見たか、閲覧したバイヤーの所在地、カバーエリア、作品がバイヤーのカートに入っている数、スクリーナーのパスワードクリック数＝再生数、バイヤーのお気に入りに入っている数、などを確認することができます。 "
        ),
      ]),
      _c("p", { staticClass: "text-gray-500" }, [
        _vm._v(
          " 作品に関する情報を入れるとどのようにそれがお客様に見えるかわかるので、作品の情報を工夫することでよりお客様に魅力的に見えるよになります。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [_vm._v("保管庫としての利用が可能に")]),
      _c("p", { staticClass: "text-gray-500" }, [
        _vm._v(
          " オンライフィルムマーケット上にアップロードし保存してあるファイルを、いつでもダウンロードできるようになります。データの保管庫としてご活用ください。 "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h3", { staticClass: "mb-3" }, [_vm._v("バイヤーへの露出アップ")]),
      _c("p", { staticClass: "text-gray-500" }, [
        _vm._v(
          "新規にご登録いただいた作品を、弊社の全てのお客様に配信する自動メールに掲載いたします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-12 text-gray-700 mx-12" }, [
      _c("p", [
        _vm._v(
          "これだけの機能が入って、最短3ヶ月契約より月1万円（税別）、年間契約ですと10万円（税別）でご提供いたします。"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "ml-2" }, [
      _c("h2", { staticClass: "font-normal text-2xl mb-3" }, [
        _vm._v("新規加入キャンペーン"),
      ]),
      _c("span", { staticClass: "text-green-100" }, [
        _vm._v(
          " 2024年12月末までにご登録いただいたお客様には、弊社の営業担当がバイヤーへのおすすめ作品を提案する際に、新規ご登録いただいた作品をかならず含めます（5万円相当）。（ご希望の作品を1度ご紹介します） "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }